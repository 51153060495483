_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Bitte geben Sie einen passenden Wert f\u00fcr {0} an.",
	"crm.label.field":"Feld",//no i18n
"crm.label.value":"Wert",//no i18n
	"sentiment.criteria.wrongcriteria":"Der Kriterienwert darf nicht gr\u00f6\u00dfer als {0} sein",//no i18n
"crm.mb.field.common.splc":"Keine Sonderzeichen zugelassen. Geben Sie einen g\u00fcltigen Wert ein.",//no i18n
	"crm.label.field.plural":"Felder",//no i18n
	"crm.label.in.minutes":"{0} (in Minuten)",//no i18n
	"crm.security.roles.list":"Rollenliste",//no i18n
"crm.security.roles.lookup.info":"Bitte w\u00e4hlen Sie eine Rolle aus der Liste.",//no i18n
"crm.territory.addterritory":"Gebiet hinzuf\u00fcgen",//no i18n
"crm.title.edit.territory":"Gebiet bearbeiten",//no i18n
"crm.territory.title.assign.territories":"Gebiete zuweisen",//no i18n
	"crm.label.context.help":"Hilfe",//no i18n
	"crm.label.from":"Von",//no i18n
"crm.label.to":"Bis",//no i18n
	"workflow.option.webhookFailure.fromDate":"Anfangsdatum",//no i18n
"workflow.option.webhookFailure.toDate":"Enddatum",//no i18n
"crm.custom.field.less.than.equalto":"{0} muss \u2264 {1}sein.",//no i18n
	"crm.wf.usage.date.criteria.error.msg":"Von-Datum kann nicht nach dem Bis-Datum liegen.",//no i18n
	"crm.template.listview.search.no.results":"Keine Ergebnisse gefunden",//No I18n
	"crm.label.tag.new":"Neues Tag",//No I18n
	"crm.label.enter.tag":"Tags eingeben",//No I18n
	"crux.comboBox.max.limit":"Sie k\u00f6nnen nur maximal {0} {1} w\u00e4hlen.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Hinzuf\u00fcgen",//NO I18n
	"crm.label.users":"Benutzer", //NO I18n
  "crm.workflow.alert.roles":"Rollen", //NO I18n
  "crm.security.groups":"Gruppen", //NO I18n
	"crm.label.available" : "Verf\u00fcgbar", //NO I18n
	"crm.label.assign.manually" : "Zuweisen", //NO I18n
	"crm.globalsearch.option.all": "Alle", //NO I18n
	"webform.status.Active":"Aktiv", //NO I18n
	"Inactive":"Inaktiv", //NO I18n
  "Confirmed":"Best\u00e4tigt", //NO I18n
  "DeletedUser":"Gel\u00f6scht", //NO I18n
  "crm.user.component.unconfirmed":"Unbest\u00e4tigt",//no i18n
  "crm.feed.group.admin":"Administrator", //NO I18n
  "crm.ln.lable.current":"Aktuelle", //NO I18n
	"crm.label.selected": "Ausgew\u00e4hlte",//NO I18n
	"crm.auditlog.user": "Benutzer", //NO I18n
	"cob.role": "Rolle", //NO I18n
	"zoho.email": "E-Mail", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.label.search.for.users": "Search Users", //NO I18n
	"crm.security.group.users.empty": "Keine Nutzer gefunden.", //NO I18n
	"crm.label.picklist.none": "Keine", //NO I18n
	"crm.label.notSelected" : "Nicht gew\u00e4hlt",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Anruf",//NO I18n
	"crm.phoneNo.Link.Title" : "\u00dcber Skype anrufen",//NO I18n
	"crm.no.data.found" : "Keine Daten gefunden.",//NO I18n
	"crm.usrpop.non.selected" : "Ausgew\u00e4hlte Nutzer",//NO I18n
	"crm.zti.label.user": "Benutzername", //NO I18n
	"crm.label.no.options.found" : "Keine Optionen gefunden.",//No I18n
	"crm.globalsearch.search.title" : "Suchen",//No I18n
	"None" : "Keine",//No I18n
	"crm.label.criteria.pattern" : "Kriterien-Pattern",//No I18n
	"crm.label.edit.criteria.pattern" : "Muster bearbeiten",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Unpassende Anzahl von Klammer im Muster.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ung\u00fcltige Klammern beim Bedingungsoperator.",//No I18n
	"crm.criteria.number.notmatch.check" : "Bitte pr\u00fcfen Sie das Muster bei {0}.",//No I18n
	"criteria.error.alert.other.params" : "Ung\u00fcltige Inhalte in diesem Muster.",//No I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Das Kriterienmuster passt nicht zu den von Ihnen gew\u00e4hlten Bedingungen.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Das Kriterienmuster passt nicht zu den von Ihnen gew\u00e4hlten Bedingungen.", //No I18n
	"and" : "und", //No I18n
	"or" : "oder", //No I18n
	"crm.label.or" : "ODER", //No I18n
	"crm.label.and" : "UND", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Bitte geben Sie eine g\u00fcltige Feldbezeichnung in die {0}-Zeile ein.", //No I18n
	"crm.criteria.condition.valid.check" : "Bitte geben Sie eine g\u00fcltige Bedingung f\u00fcr {0} an.", //No I18n
	"crm.field.valid.check" : "Bitte {0} richtig eingeben.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Von</i>-Bereich darf nicht h\u00f6her als <i>Bis</i>-Bereich sein.", //No I18n
	"crm.alert.label.savepattern" : "Speichern Sie das Muster, bevor Sie Kriterien \u00e4ndern.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Sie k\u00f6nnen nicht zus\u00e4tzliche Kriterien hinzuf\u00fcgen.",//No I18n
	"is" : "ist",//No I18n
	"isn\'t" : "ist nicht",//No I18n
	"contains" : "enth\u00e4lt",//No I18n
	"doesn\'t contain" : "enth\u00e4lt nicht",//No I18n
	"starts with" : "beginnt mit",//No I18n
	"ends with" : "endet mit",//No I18n
	"is empty" : "ist leer",//No I18n
	"is not empty" : "ist nicht leer",//No I18n
	"is before" : "ist vor",//No I18n
	"is after" : "ist nach",//No I18n
	"between" : "zwischen",//No I18n
	"not between" : "nicht inzwischen",//No I18n
	"Today" : "Heute",//No I18n
	"Tommorow" : "Morgen",//No I18n
	"Tommorow Onwards" : "Morgen geh\u2019s los",//No I18n
	"Yesterday" : "Gestern",//No I18n
	"Till Yesterday" : "Bis gestern",//No I18n
	"Last Month" : "Letzten Monat",//No I18n
	"Current Month" : "Aktueller Monat", //No I18n
	"Next Month" : "N\u00e4chster Monat", //No I18n
	"Last Week" : "Letzte Woche", //No I18n
	"Current Week" : "Aktuelle Woche", //No I18n
	"Next Week" : "N\u00e4chste Woche", //No I18n
	"Age in Days" : "Alter in Tagen", //No I18n
	"Due in Days" : "F\u00e4lligkeit in Tagen", //No I18n
	"Scheduled" : "Geplant", //No I18n
	"Attended Dialled" : "Bedient, Gew\u00e4hlt", //No I18n
	"Unattended Dialled" : "Nicht bedient, Gew\u00e4hlt", //No I18n
	"Overdue" : "\u00dcberf\u00e4llig", //No I18n
	"Cancelled" : "Abgesagt", //No I18n
	"Received" : "Empfangen", //No I18n
	"Missed" : "Verpasst", //No I18n
	"crm.alert.character.not.allowed" : "{0} ist nicht zul\u00e4ssig", //No I18n
	"crm.condition.in.last" : "in den letzten", //No I18n
	"crm.zinvoice.dueIn" : "f\u00e4llig in", //No I18n
	"on" : "Am",//No I18n
	"before" : "fr\u00fcher",//No I18n
	"crm.label.general.small.after" : "nach",//No I18n
	"crm.thisweek" : "Diese Woche",//No I18n
	"crm.label.this.month" : "Dieser Monat",//No I18n
	"crm.thisyear" : "Dieses Jahr",//No I18n
	"crm.source.user.and.system" : "Nutzer und System",//No I18n
	"crm.source.user.or.system" : "Nutzer oder System",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Nur von Nutzer",//No I18n
	"crm.source.system.only" : "Nur von System",//No I18n
	"crm.condition.till.today" : "Bis heute",//No I18n
	"game.month.left" : "1 Monat",//No I18n
	"game.months.left" : "{0} Monate",//No I18n
	"crm.condition.last.30.days" : "in den letzten 30 Tagen",//No I18n
	"crm.condition.last.60.days" : "in den letzten 60 Tagen",//No I18n
	"crm.condition.last.90.days" : "in den letzten 90 Tagen",//No I18n
	"crm.label.filter.typehere" : "Hier tippen", //No I18N
	"crm.filter.is.not" : "ist nicht", //No I18n
	"crm.condition.until.now" : "Bis jetzt",//No I18n
	"crm.filter.email.isblocked" : "ist blockiert",//No I18n
	"crm.filter.email.isnotblocked" : "ist nicht blockiert",//No I18n
	"crm.label.no.results.match" : "Keine \u00fcbereinstimmenden Ergebnisse",//No I18n
	"crm.label.select.user" : "Klicken Sie zum Ausw\u00e4hlen von Nutzern.", //No I18n
	"current.logged.in.user": "Angemeldeter Nutzer", //NO I18n
	"current.logged.in.user.definition": "Der Nutzer, der die Aufzeichnungsaktion startete.", //NO i18n
	"crm.security.group": "Gruppe", //NO I18n
	"crm.security.role": "Rolle", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Dezimalstellen des <i>{0}</i>-Feldes d\u00fcrfen {1} nicht \u00fcberschreiten.",//No I18n
	"crm.field.empty.check" : "{0} darf nicht leer sein.",//No I18n
	"crm.label.add.note": "Anmerkung hinzuf\u00fcgen", //NO I18n
	"crm.label.simply.by": "bei", //NO I18n
	"crm.general.addnote": "Eine Notiz hinzuf\u00fcgen", //NO I18n
	"crm.general.addtitle": "Einen Titel hinzuf\u00fcgen", //NO I18n
	"crm.label.attach.file": "Dateien anh\u00e4ngen", //NO I18N
	"crm.button.cancel": "Cancel", //NO I18N
	"crm.button.save": "Speichern", //NO I18N
	"crm.button.mass.delete": "L\u00f6schen", //NO I18N
	"crm.warning.delete.record": 'M\u00f6chten Sie \u201e{0}\u201c wirklich in den Papierkorb verschieben?', //NO I18N
	"crm.label.yes": "Ja", //NO I18N
	"crm.note.view.previous": "Vorherige Bemerkungen anzeigen", //NO I18N
  "of": "von", //NO I18N
	"crm.label.notes": "Notizen", //NO I18N
	"crm.note.recent.first": "K\u00fcrzlich, zuerst", //NO I18N
	"crm.note.recent.last": "K\u00fcrzlich, zuletzt", //NO I18N
	"crm.territory.label.only": "Nur {0}", //no i18n
	"crm.select" : "Ausw\u00e4hlen",//No I18n
	"crm.button.apply.filter" : "Filter anwenden",//No I18n
	"crm.alert.maximum.text.values.contains" : "Bei diesem Feld d\u00fcrfen Sie nicht mehr als {0} Werte eingeben.",//No I18n
	"PM" : "PM",//No I18n
	"Jan" : "Jan",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "M\u00e4r",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Aug",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Okt",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dez",//No I18n
	"crm.mb.newversion.msg4" : "OK, verstanden!!", //no i18n
	"crm.label.More" :"Mehr", //no i18n

	"crm.label.unmapped.stages":"Nicht ber\u00fccksichtigt", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Mehr anzeigen",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Weniger anzeigen",//No I18n

	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"Hoch",//no i18n
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.convert.medium":"Mittel",//no i18n
	"crm.lead.prediction.convert.low":"Niedrig",//no i18n
	"crm.lead.prediction.tooltip.convert":"WAHRSCHEINLICHKEIT",//no i18n
	"crm.lead.prediction.tooltip.score":"PUNKTZAHL",//no i18n
	"Planned":"Geplant",//no i18n
	"Invited":"Eingeladen",//no i18n
	"Sent":"Gesendet",//no i18n
	"Received":"Empfangen",//no i18n
	"Opened":"Ge\u00f6ffnet",//no i18n
	"Responded":"Beantwortet",//no i18n
	"Bounced":"Unzustellbar",//no i18n
	"Opted\ Out":"Ausgestiegen",//no i18n
	"crm.filter.label.with.open":"Mit offenen {0}",//no i18n
	"crm.filter.label.without.open":"Ohne offene {0}",//no i18n
	"crm.filter.label.without.any":"Ohne jegliche {0}",//no i18n
	"crm.filter.label.with.module":"Mit {0}",//no i18n
	"crm.filter.label.activity.due":"{0} f\u00e4llig",//no i18n
	"crm.filter.label.activity.done":"{0} erledigt",//no i18n
	"Notes":"Notizen",//no i18n
	"crm.filter.label.notes.added":"Anmerkungen hinzugef\u00fcgt",//no i18n
	"crm.label.filter.email.status":"Neuester E-Mail-Status",//no i18n
	"crm.label.filter.email.clicked":"geklickt",//no i18n
	"crm.label.filter.email.responded":"beantwortet", //no i18n
	"crm.label.filter.email.info":"Filtern Sie Eintr\u00e4ge basierend auf dem neuesten Status Ihrer gesendeten/empfangenen E-Mails.",//no i18n
	"crm.filter.label.sent":"gesendet",//no i18n
	"crm.filter.label.not.sent":"nicht gesendet",//no i18n
	"crm.filter.label.opened":"ge\u00f6ffnet",//no i18n
	"crm.filter.label.not.opened":"nicht ge\u00f6ffnet",//no i18n
	"crm.filter.label.received":"empfangen",//no i18n
	"crm.filter.label.not.received":"nicht empfangen",//no i18n
	"crm.filter.label.bounced":"abgelehnt",//no i18n
	"crm.filter.label.opened.not.replied":"ge\u00f6ffnet, nicht beantwortet", //no i18n
	"crm.filter.label.any":"Beliebige obige Angaben",//no i18n
	"crm.zia.config.potential.amount":"{0} Betrag",//no i18n
	"Quote\ Stage":"{0}-Stufe",//no i18n
	"crm.module.owner":"{0}: Besitzer",//no i18n
	"Potential\ Closing\ Date":"{0}-Abschlussdatum",//no i18n
	"crm.lead.prediction.likely.convert":"Wahrscheinlich zur Konvertierung",//no i18n
	"crm.predictions.feature.label":"Prognose",//no i18n
	"crm.intelligence.prediction.likelywin":"Gewinn wahrscheinlich",//no i18n
	"crm.intelligence.prediction.likelylose":"Verlust wahrscheinlich",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50-Chance",//no i18n
	"crm.intelligence.prediction.score":"Prognosepunktzahl",//no i18n
	"crm.lead.prediction.recent.score":"Letzte Prognose-Punktzahl",//no i18n
	"crm.intelligence.prediction.lastconv":"Letzte 3 Konversationen",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Eintr\u00e4ge zum Fokussieren",//no i18n
	"crm.intelligence.prediction.slowmoving":"Langsame Bewegung",//no i18n
	"crm.intelligence.prediction.trend.down":"Zuletzt Abw\u00e4rtstrend",//no i18n
	"crm.label.touched.records":"Bearbeitete Eintr\u00e4ge",//no i18n
	"crm.label.untouched.records":"Unbearbeitete Eintr\u00e4ge",//no i18n
	"crm.label.record.action":"Aktion aufzeichnen",//no i18n
	"workflow.rule.view.label.Modified":"Ge\u00e4ndert",//no i18n
	"crm.label.not.modified":"Nicht ver\u00e4ndert",//no i18n
	"crm.label.related.records.action":"Zugeh\u00f6rige-Eintr\u00e4ge-Aktion",//no i18n
	"Done":"Fertig",//no i18n
	"crm.label.not.done":"Nicht ausgef\u00fchrt",//no i18n
	"sentiment.model":"E-Mail-Stimmung",//no i18n
	"sentiment.criteria.count":"Anzahl",//no i18n
	"sentiment.criteria.percentage":"Prozent",//no i18n
	"sentiment.criteria.lastmail":"F\u00fcr die letzte E-Mail",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"Teilgenommen",//no i18n
	"crm.lead.prediction.popup.text":"Wenn Umwandlung wahrscheinlich {0} ist, sollte die Prognosepunktzahl zwischen {1} liegen.",//no i18n
	"crm.lead.prediction.popup.final":"Bitte \u00e4ndern Sie den Filter entsprechend, versuchen Sie es dann noch einmal.",//no i18n
	"crm.custom.field.less.than.to1":"Anfangsbereich darf nicht gr\u00f6\u00dfer als Zielbereich sein.",//no i18n
	"Last\ Activity\ Date":"Letztes Aktivit\u00e4tsdatum",//no i18n
	"crm.label.vendor.name":"{0}-Name",//no i18n
	"hours":"Stunden",//no i18n
	"days":"Tage",//no i18n
	"weeks":"Wochen",//no i18n
	"months":"Monate",//no i18n
	"years":"Jahre",//no i18n
	"crm.label.general.small.after":"nach",//no i18n
	"Last\ Week":"Letzte Woche",//no i18n
	"Last\ Month":"Letzten Monat",//no i18n
	"crm.module.name":"{0}-Name",//no i18n
	"Campaign":"Kampagne",//no i18n
	"Tasks":"Aufgaben",//no i18n
	"Calls":"Anrufe",//no i18n
	"Events":"Ereignisse",//no i18n
	"sentiment.criteria.wrongcriteria":"Der Kriterienwert darf nicht gr\u00f6\u00dfer als {0} sein",//no i18n
	"crm.chosen.minimum.input.text":"Bitte mindestens  {0} Zeichen eingeben",//no i18n
	"crm.intelligence.prediction.trendup":"Aufw\u00e4rtstrend",//no i18n
	"crm.intelligence.prediction.trenddown":"Abw\u00e4rtstrend",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Abgeschlossen" ,//no i18n
	"crm.label.success":"Erfolg" ,//no i18n
	"crm.label.Failure":"Fehler" ,//no i18n
	"Both":"Beides" ,//no i18n
	"crm.condition.cannot.empty":"Bedingung darf nicht leer sein.",//no i18n
	"crm.condition.last.30.days":"in den letzten 30 Tagen",//no i18n
	"crm.condition.last.60.days":"in den letzten 60 Tagen",//no i18n
	"crm.condition.last.90.days":"in den letzten 90 Tagen",//no i18n
	"crm.sentiment.Positive":"Positiv",//no i18n
	"crm.sentiment.Negative":"Negativ",//no i18n
	"sentiment.positiveandnegative":"Positiv und negativ",//no i18n
	"sentiment.positiveornegative":"Positiv oder negativ",//no i18n
	"sentiment.positiveonly":"Nur positiv",//no i18n
	"sentiment.negativeonly":"Nur negativ",//no i18n
	"crm.sentiment.Neutral":"Neutral",//no i18n
	"crm.filters.select.campaign.type":"{0}-Typ w\u00e4hlen",//no i18n
	"crm.filters.select.campaign.status":"{0}-Status w\u00e4hlen",//no i18n
	"campaign.Member" : "Mitglied",//no i18n
	"Service":"Service",//no i18n
	"Activities":"Aktivit\u00e4ten",//no i18n
	"crm.livedesk.pot.nextdays":"N\u00e4chste {0} Tage",//no i18n
	"Today\ +\ Overdue":"Heute + \u00dcberf\u00e4llig",//no i18n
	"crm.source.user.and.system":"Nutzer und System",//no i18n
	"crm.source.user.or.system":"Nutzer oder System",//no i18n
	"User":"Benutzer",//no i18n
	"crm.source.user.only":"Nur von Nutzer",//no i18n
	"crm.source.system.only":"Nur von System",//no i18n
	"Scheduled":"Geplant",//no i18n
	"Attended\ Dialled":"Bedient, Gew\u00e4hlt",//no i18n
	"Unattended\ Dialled":"Nicht bedient, Gew\u00e4hlt",//no i18n
	"Cancelled":"Abgesagt",//no i18n
	"crm.filter.email.isblocked":"ist blockiert",//no i18n
	"crm.filter.email.isnotblocked":"ist nicht blockiert",//no i18n
	"condition.till.now":"Bis jetzt",//no i18n
	"crm.recurring.no.months":"{0} Monate",//no i18n
	"crm.lead.prediction.tooltip":"Wahrscheinlich zur Konvertierung - Punktzahl-Bereich",//no i18n
	"crm.website.activity":"Website-Aktivit\u00e4t",//no i18n
	"crm.label.By":"Von",//no i18n
	"crm.chosen.searching.text":"Suche \u2026",//no i18n
	"crm.label.memberstatus.is":", der Mitgliedsstatus lautet",//no i18n
	"crm.events.duration":"Dauer",//no i18n
	"crm.title.clear.name":"L\u00f6schen",//no i18n
	"crm.label.status.is":", der Status ist",//no i18n
	"zia.last3.help":"Konversationen umfassen Anrufe, Aufgaben, {0}, empfangene E-Mails, Anmerkungen, Besuche, Sozialkommentare, Kundendienstanfragen von Desk.",//no i18n
	"crm.label.tag.related.to":"bezieht sich auf",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Neue(r) {0} wird erstellt.",//No I18n
	"crm.krp.no.records.found" : "Keine {0} gefunden",//No I18n
	"crm.module.new" : "{0} erstellen",//No I18n
	"crm.label.view" : "Anzeigen",//No I18n
	"crm.nsocial.customers" : "Kunden",//No I18n
	"crm.nsocial.open.potential" : "Offene {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Sonstige",//No i18n
	"crm.field.length.check" : "{0}-Wert \u00fcberschreitet die Maximall\u00e4nge.", //No I18n
	"crm.lower.now":"jetzt",//no i18n
	"crm.time.min.ago":"{0} Minuten zuvor",//no i18n
	"crm.time.mins.ago":"{0} Minuten zuvor",//no i18n
	"crm.time.hr.ago":"{0} Stunde zuvor",//no i18n
	"crm.time.hrs.ago":"{0} Stunden zuvor", //no i18n
	"AllUsers": "Alle Benutzer", //no i18n
	"crm.label.search":"Suchen",//no i18n
	"crm.api.filterby":"Filtern nach",//no i18n
	"crm.customview.nofields.found":"-- Keine passenden Felder --",//no i18n
	"crm.setup.system.ziarecommendation":"Empfehlung",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//no i18n
	"crm.filter.label.select.products":"Ausgew\u00e4hlte {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Pr\u00fcfungsprozesseintragsstatus",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Bitte Wert w\u00e4hlen.",//no i18n
	"crm.segmentation.segment.score":"Segmentbewertung",//no i18n
	"crm.filter.label.in":"basierend auf",//no i18n
	"crm.filter.label.and.purchase.in":"und wahrscheinlich zum Kauf in",//no i18n
	"crm.filter.label.last.purchased":"und vor kurzem erworben",//no i18n
	"crm.filter.label.a.day":"ein Tag",//no i18n
	"crm.filter.label.a.week":"eine Woche",//no i18n
	"crm.filter.label.a.month":"ein Monat",//no i18n
	"crm.cal.custom":"Spezifisch",//no i18n
	"crm.mb.field.common.empt":"Der Wert darf nicht leer sein.",//no i18n
	"crm.chosen.error.loading.text":"Hoppla, wir konnten Ihre Ergebnisse nicht laden",//no i18n
	"crm.filter.label.firstbuy":"Erstes Mal",//no i18n
	"crm.filter.label.cwbab":"Abh\u00e4ngig",//no i18n
	"crm.filter.label.fbt":"B\u00fcndel",//no i18n
	"crm.filter.label.rebuy":"Wiederholen",//no i18n
	"crm.filter.label.nextbuy":"Sequenz",//no i18n
	"crm.mxnlookup.select" : "{0} verkn\u00fcpfen",//No I18n
	"crm.lookup.chooserecord":"{0} w\u00e4hlen",//no i18n
	"crm.record.selected":"Ausgew\u00e4hlte {0}",//no i18n
	"crm.module.empty.message" : "Keine {0} gefunden",//No I18n
	"crm.mxnlookup.selected" : "{0} verkn\u00fcpft",//No I18n
	"crm.security.error" : "Ihre Berechtigungen reichen nicht zum Ausf\u00fchren dieser Aktion aus. Wenden Sie sich an Ihren Administrator.", //no i18n
	"crm.label.creator.noPermission"  : "Berechtigung verweigert", //no i18n
	"crm.segmentation.recency" : "Aktualit\u00e4t", //no i18n
	"crm.segmentation.frequency" : "Frequenz",//no i18n
	"crm.segmentation.monetary" : "Geldwert",//no i18n
	"crm.smartfilter.related.module.msg" : "Sie k\u00f6nnen nur maximal drei zugeh\u00f6rige Module w\u00e4hlen.", //no i18n
	"crm.smartfilter.related.module.msg1" : "(Beispiel: E-Mail, Aktivit\u00e4ten, Anmerkungen)", //no i18n
	"crm.smartfilter.related.module.msg2" : "Dauer darf nicht leer sein", //no i18n
	"crm.label.timeZone": "Zeitzone", //NO I18n
	"crm.label.insufficient.privileges": "Unzureichende Berechtigungen zum Ausf\u00fchren dieser Aktion. Wenden Sie sich an Ihren Administrator.", //NO I18n
	"crm.filter.header.secton.system": "Systemdefinierte Filter", //NO I18N
	"crm.filter.header.secton.fields": "Nach Feldern filtern nach Feld", //NO I18N
	"crm.createfield.calcinfo.new" : "Dieses Feld dient zur Berechnung s\u00e4mtlicher Ausdr\u00fccke Ihrer Eingabe.</br> <b>Beispielsweise ergibt 20 + 20</b> automatisch <b>40</b>",//No i18n
	"crm.lable.read.only" : "Schreibgesch\u00fctztes Feld",//No i18n
	"crm.column.sort.asc" : "Aufst",//No I18n
	"crm.column.sort.desc" : "Abst",//No i18n
	"crm.column.unsort" : "Sortierung aufheben",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Mit Kunde teilen", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "F\u00fcr {1} sind nur {0} Zeichen erlaubt.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Mit Kunden geteilt", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" : "Diese Rolle wurde bereits ausgew\u00e4hlt", //no i18n
	"crm.user.deleted": "NUTZER WURDE GEL\u00d6SCHT",  //NO I18N
	"crm.account.closed": "DIESES KONTO WURDE GESCHLOSSEN",  //NO I18N
	"crm.start.chat": "Chat starten",  //NO I18N
	"crm.start.call": "Anruf starten",  //NO I18N
	"crm.recipient.invalid.email" : "Ung\u00fcltige E-Mails gefunden.", //NO I18N
	"crm.recipient.add.recipient" : "Zus\u00e4tzlichen Empf\u00e4nger hinzuf\u00fcgen", //NO I18N
	"crm.start.video.call": "Videoanruf starten",  //NO I18N //ignorei18n_start

	"Score":"Punktzahl",
	"Positive Score":"Positive Punktzahl",
	"Negative Score":"Negative Punktzahl",
	"Touch Point Score":"Ansatzpunktzahl",
	"Positive Touch Point Score":"Positive Ansatzpunktzahl",
	"Negative Touch Point Score":"Negative Ansatzpunktzahl",
	"crm.label.scoring.rules":"Bewertungsregeln",
	"crm.label.type.minutes": "Minuten hier eingeben", //NO I18N
	"is\ OPEN":"ist OFFEN",//no i18n
	"is\ WON":"ist GEWONNEN",//no i18n
	"is\ LOST":"ist VERLOREN",//no i18n
	"crm.potential.all.open":"Alle offenen Stufen",//no i18n
	"crm.potential.all.won":"Alle geschlossenen gewonnenen Stufen",//no i18n

	"crm.potential.all.lost":"Alle geschlossenen verlorenen Stufen",//no i18n
	"crm.campaign.member.status" : "Mitgliederstatus",//no i18n
	"crm.dashboard.select.type" : "{0} w\u00e4hlen",//no i18n
	"crm.campaign.service.status":"Servicestatus",//no i18n

	"crm.label.addColumn":"Spalte hinzuf\u00fcgen",//no i18n
	"crm.button.clear.filter":"Filter schlie\u00dfen",//no i18n
	"crm.button.show.filter":"Filter anzeigen",//no i18n
	"crm.las.error.user.maxlimit":"Sie k\u00f6nnen nur bis zu 20 Nutzer w\u00e4hlen.",//no i18n
	"crm.las.error.picklist.maxlimit":"Sie k\u00f6nnen nur bis zu 20 Optionen w\u00e4hlen.",//no i18n

	"crm.fileuploader.message.responseerror": "Hochladen fehlgeschlagen", //NO I18N
	"crm.storage.create.error":"Keine neuen Eintr\u00e4ge k\u00f6nnen erstellt werden, da Sie Ihr maximales Datenspeicherlimit erreicht haben.",//no i18n
	"crm.storage.create.error.client":"Keine neue Eintr\u00e4ge k\u00f6nnen erstellt werden, da Ihr Administrator das maximale Datenspeicherlimit erreicht hat. Kontaktieren Sie zum L\u00f6sen des Problems {0}.",//no i18n
	"crm.storage.avail.info":"({0} von {1} verbleibend)",//no i18n
	"crm.storage.error.key.manage":"Datenspeicher verwalten",//no i18n
	"Records":"Datens\u00e4tze",//no i18n
	"crm.workflow.alert.additional.recipients" : "Weitere Empf\u00e4nger", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Trennen Sie zus\u00e4tzliche E-Mail-Adressen mit Kommas ab.", //NO I18N
	"crm.related.contact.account" : "{0} bezogen auf {1}",//No I18n
	"crm.allcontact.show" : "Alle {0}",//No I18n
	"crm.button.mass.show" : "Zeigen",//No I18n
	"crm.msg.custom.view.not.replied" : "Nicht beantwortete Mitteilungen", //NO I18N
	"crm.msg.custom.view.replied" : "Beantwortete Mitteilungen",//NO I18N
	"crm.workflow.select.recipients" : "Empf\u00e4nger", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Bitte w\u00e4hlen Sie mindestens ein Profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Sie k\u00f6nnen ein Standardprofil nicht l\u00f6schen.",//NO I18N
	"crm.inv.label.add.emails" : "E-Mail-Adressen hinzuf\u00fcgen" ,//NO I18N
	"crm.prediction.analytics.filter.year":"Letztes Jahr",//no i18n
	"Previous\ FY":"Letztes FJ",//no i18n
	"Current\ FY":"Aktuelles FJ",//no i18n
	"Next\ FY":"N\u00e4chstes FJ",//no i18n
	"Current\ FQ":"Aktuelles FQ",//no i18n
	"Next\ FQ":"N\u00e4chstes FQ",//no i18n
	"Previous\ FQ":"Letzte FQ",//no i18n
	"crm.inv.label.add.emails" : "E-Mail-Adressen hinzuf\u00fcgen", //NO I18N
	"crm.picklist.sample.text":"Beispieltext",//no i18n
	"crm.more.colors":"Mehr Farben",//no i18n
	"crm.button.back.alone":"Zur\u00fcck",//no i18n
	"crm.field.label.email":"E-Mail",//no i18n
"crm.zia.nba.feature.label":"N\u00e4chste Bestaktion",//no i18n
"Meeting":"Meeting",//no i18n
"Tomorrow":"Morgen",//no i18n
"crm.gdpr.notavailable.field":"Nicht verf\u00fcgbar",//no i18n
	"crm.setup.system.ziasimilarity":"\u00c4hnlichkeitsempfehlung",//no i18n
	"crm.gdpr.notavailable.field":"Nicht verf\u00fcgbar",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics" ,// NO I18N
	"crux.custom.field.greater.than.equalto":"{0} muss ≥ {1}sein.",
	"crux.users.selected.plural" : "{0} Nutzer ausgewählt.",
	"crux.user.selected.singular" :"{0} Nutzer ausgewählt.",
	"crux.criteria.empty.secondaryfield.module" : "Kein passendes {0}-Feld im {1}-Modul gefunden.",
	"crux.criteria.empty.secondaryfield" : "Kein weiteres {0}-Feld zum Vergleich verfügbar. Bitte geben Sie einen Wert zum Vergleich ein.",
	"crux.logged.in.role.definition" : "Die Nutzerrolle, die Aufzeichnungsaktionen startet.",
	"zia.similarity.smartfilter.score":"\u00c4hnlichkeitswert",//no i18n
	"zia.similarity.smartfilter.records":"\u00c4hnlich {0} von",//no i18n
	"zia.similarity.smartfilter.records.search":"\u00c4hnliche {0} anzeigen von",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Sie k\u00f6nnen nur maximal {0} {1} ausw\u00e4hlen",//no i18n
	"abm.firmographics": "Firmographics" ,// NO I18N
	"crux.custom.field.greater.than.equalto":"{0} muss ≥ {1}sein.",
	"crux.users.selected.plural" : "{0} Nutzer ausgewählt.",
	"crux.user.selected.singular" :"{0} Nutzer ausgewählt.",
	"crux.criteria.empty.secondaryfield.module" : "Kein passendes {0}-Feld im {1}-Modul gefunden.",
	"crux.criteria.empty.secondaryfield" : "Kein weiteres {0}-Feld zum Vergleich verfügbar. Bitte geben Sie einen Wert zum Vergleich ein.",
	"crux.logged.in.role.definition" : "Die Nutzerrolle, die Aufzeichnungsaktionen startet.",
	"crux.max.limit.unselect" : "Sie können nur bis zu {0} {1} abwählen.", //NO I18N
	"crux.existing.tag" : "\u201e{0}\u201c ist bereits ausgewählt." //NO I18N
}
